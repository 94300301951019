import React, { useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';

const CustomPagination = ({ page, limit, setPage, setLimit, totalItems }) => {
  useEffect(() => {
    // Ensure that the page doesn't exceed the total number of pages
    if (page > Math.ceil(totalItems / limit) - 1) {
      setPage(0); // Reset to the first page if the page exceeds total pages
    }
  }, [totalItems, limit, page, setPage]);

  const handleChangeRowsPerPage = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <TablePagination
        component="div"
        count={totalItems} // Use totalItems here
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15, 20, 50]} // Customize these values
      />
    </div>
  );
};

export default CustomPagination;
