import React, { useEffect, useState } from "react";
import Header from "../../../components/AdminPanelComponents/Header";
import Sidebar from "../../../components/AdminPanelComponents/Slidebar";
import SucessSnackBar from "../../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../../components/SnackBars/SnackBar";
import { Button, IconButton, Modal } from "@mui/material";
import Add from "./Add";
import api from "../../../helper/api";
import { SERVICES } from "../../../helper/endpoints";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Edit from "./Edit";
import { pagesData } from "../../../common/Data/PagesData";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: 12,
};

const ServiceManagement = () => {
  const adminDetails = useSelector((state) => state.adminReducer.data);
console.log(adminDetails);

  const [permission, setPermission] = useState({ actions: {} });
  const [open, setOpen] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState("");
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [imagesModal, setImagesModal] = useState([]);
  const [imagesOpen, setImagesOpen] = useState(false);
  const [headerImagesModal, setHeaderImagesModal] = useState("");
  const [headerimagesOpen, setHeaderImagesOpen] = useState(false);
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [editmodel, setEditmodel] = useState(false);
  const [myedit, setMyedit] = useState({});
  const [editSucessSnackBarOpen, setEditSucessSnackBarOpen] = useState(false);
  const [deleteSucessSnackBarOpen, setDeleteSucessSnackBarOpen] =
    useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [editWarningSnackBarOpen, setEditWarningSnackBarOpen] = useState(false);
  const [deleteWarningSnackBarOpen, setDeleteWarningSnackBarOpen] =
    useState(false);

  const [services, setServices] = useState([]);
  console.log(services);
  
  const getAllServicesData = async () => {
    try {
      const { data } = await api.get(`${process.env.REACT_APP_API + SERVICES}`);
      setServices(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpen = (id) => {
    setOpen(true);
    setModelData(services.filter((e) => e._id === id));
  };

  const handleClose = () => {
    setOpen(false);
    setModelData("");
  };
  const handleDescriptionOpen = (desc) => {
    setDescriptionModal(desc);
    setDescriptionOpen(true);
  };
  const handleDescriptionclose = () => {
    setDescriptionModal("");
    setDescriptionOpen(false);
  };
  const handleimagesOpen = (imgs) => {
    setImagesModal(imgs);
    setImagesOpen(true);
  };
  const handleimagesclose = () => {
    setImagesModal([]);
    setImagesOpen(false);
  };
  const handleHeaderOpen = (desc) => {
    setHeaderImagesModal(desc);
    setHeaderImagesOpen(true);
  };
  const handleHeaderclose = () => {
    setHeaderImagesModal("");
    setHeaderImagesOpen(false);
  };
  const handelDelete = async (id) => {
    try {
      const response = await api.delete(
        `${process.env.REACT_APP_API + SERVICES + "?id=" + id}`
      );
      if (response.data.success) {
        setDeleteSucessSnackBarOpen(true);
        getAllServicesData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handelEdit = (id) => {
    setMyedit(...services.filter((e) => e._id === id));
    setEditmodel(true);
  };
  useEffect(() => {
    getAllServicesData();
  }, []);
  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      const currentData = adminDetails.role_ID.permissions.filter(
        (res) => res.module === pagesData.servicesManagement
      );
      if (currentData.length !== 0) {
        setPermission(currentData[0]);
      }
    }
  }, [adminDetails.role_ID]);
  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <div className=" bg-white container mx-auto">
            <div className="p-5 w-full">
              <header className="flex justify-between relative py-4 border-b border-slate-100">
                <h2 className="text-sm  font-semibold text-slate-800">
                  All Services
                </h2>

                <div className="flex gap-1 ">
                  <div>
                    <Button
                      onClick={() => setAddModel(true)}
                      variant="contained"
                      sx={{ color: "white" }}
                      disabled={!permission.actions.create}
                    >
                      Add Services
                    </Button>
                  </div>
                </div>

                <Add
                  addModel={addModel}
                  setAddModel={setAddModel}
                  getAllServicesData={getAllServicesData}
                  setSucessSnackBarOpen={setSucessSnackBarOpen}
                  setWarningSnackBarOpen={setWarningSnackBarOpen}
                />
                <Edit
                  myedit={myedit}
                  editmodel={editmodel}
                  setEditmodel={setEditmodel}
                  getAllServicesData={getAllServicesData}
                  setEditSucessSnackBarOpen={setEditSucessSnackBarOpen}
                  setEditWarningSnackBarOpen={setEditWarningSnackBarOpen}
                />
              </header>

              <div className="overflow-x-auto">
                <table className=" w-full table-auto">
                  <thead className=" font-semibold uppercase text-slate-400 bg-slate-50">
                    <tr>
                      <th className="p-2 whitespace-nowrap ">
                        <div className="text-[14px] font-semibold text-left">
                          Hero Image
                        </div>
                      </th>
                      <th className="p-2 whitespace-nowrap ">
                        <div className="text-[14px] font-semibold text-left">
                          title
                        </div>
                      </th>
                      <th className="p-2 whitespace-nowrap ">
                        <div className="text-[14px] font-semibold text-left">
                          Header
                        </div>
                      </th>
                      <th className="p-2 whitespace-nowrap ">
                        <div className="text-[14px] font-semibold text-left">
                          Description
                        </div>
                      </th>
                      <th className="p-2 whitespace-nowrap ">
                        <div className="text-[14px] font-semibold text-left">
                          Points
                        </div>
                      </th>
                      <th className="p-2 whitespace-nowrap ">
                        <div className="text-[14px] font-semibold text-left">
                          Images
                        </div>
                      </th>
                      <th className="p-2 whitespace-nowrap ">
                        <div className="text-[14px] font-semibold text-left">
                          Heading Image
                        </div>
                      </th>
                      <th className="p-2 whitespace-nowrap ">
                        <div className="text-[14px] font-semibold text-left">
                          Action
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {services?.map((data) => (
                    <tbody key={data._id}>
                      <tr className="border-b-2 hover:bg-slate-50">
                        <td className="p-2 whitespace-nowrap ">
                          <div className="text-[20px]  text-blue">
                            <img
                              className="text-left mb-1 w-[100px] h-[100px]"
                              src={
                                `${process.env.REACT_APP_UPLOADS}` +
                                data.heroImages
                              }
                            />
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap ">
                          <div className="text-[20px] text-left text-blue">
                            {data.title}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap ">
                          <div className="text-[20px] text-left text-blue">
                            {data.header.slice(0, 50)}...
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap ">
                          <div className="text-[20px] text-left text-blue">
                            <button
                              onClick={() =>
                                handleDescriptionOpen(data.description)
                              }
                              disabled={!permission.actions.view}
                              className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            >
                              View More
                            </button>
                          </div>
                        </td>

                        <td className="p-2 whitespace-nowrap ">
                          <div className="text-[20px] text-left text-blue">
                            <button
                              onClick={() => handleOpen(data._id)}
                              className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                              disabled={!permission.actions.view}
                            >
                              View More
                            </button>
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap ">
                          <div className="text-[20px] text-left text-blue">
                            <button
                              onClick={() => handleimagesOpen(data.images)}
                              className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                              disabled={!permission.actions.view}
                            >
                              View More
                            </button>
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap ">
                          <div className="text-[20px] text-left text-blue">
                            <button
                              onClick={() =>
                                handleHeaderOpen(data.headingImages)
                              }
                             className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                             disabled={!permission.actions.view}
                            >
                              View More
                            </button>
                          </div>
                        </td>

                        <td className="p-2 whitespace-nowrap ">
                          <div className="text-[#1e293b]">
                            <IconButton
                              onClick={() => {
                                handelDelete(data._id);
                              }}
                              disabled={!permission.actions.delete}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                handelEdit(data._id);
                              }}
                              disabled={!permission.actions.update}
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
            {/* <nav className="p-3" aria-label="Page navigation example">
              <Stack spacing={2}>
                <Pagination
                  count={totalPage}
                  page={page}
                  pages={totalPage}
                  onChange={(e, value) => setPage(value)}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </nav> */}
          </div>
        </div>

        <SucessSnackBar
          open={sucessSnackBarOpen}
          setOpen={setSucessSnackBarOpen}
          text="Image Added Successfully !"
          type="success"
        />
        <ErrorSnackBar
          open={warningSnackBarOpen}
          setOpen={setWarningSnackBarOpen}
          text="Something Missing !"
          type="error"
        />

        <SucessSnackBar
          open={editSucessSnackBarOpen}
          setOpen={setEditSucessSnackBarOpen}
          text="Image Edited Successfully !"
          type="success"
        />
        <ErrorSnackBar
          open={editWarningSnackBarOpen}
          setOpen={setEditWarningSnackBarOpen}
          text="Something Missing !"
          type="error"
        />

        <SucessSnackBar
          open={deleteSucessSnackBarOpen}
          setOpen={setDeleteSucessSnackBarOpen}
          text="Services Deleted Successfully !"
          type="success"
        />
        <ErrorSnackBar
          open={deleteWarningSnackBarOpen}
          setOpen={setDeleteWarningSnackBarOpen}
          text="Something Missing !"
          type="error"
        />
      </div>

      {/* points modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <div
          style={style}
          className="w-[90%] md:w-[80%]  mt-10 mx-auto relative"
        >
          <table className=" table-auto flex flex-col justify-between order-1 w-full px-2 py-2 bg-white md:order-none md:py-4 md:px-5 lg:px-8 2xl:px-12 rounded-2xl">
            <thead className="font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="text-[24px] font-semibold text-center">
                    Points
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {modelData &&
                modelData?.map((data, index) => (
                  <div key={index}>
                    <div className="text-[20px] text-left text-blue  flex flex-col p-3">
                      <div className="grid">
                        {data.points.map((e) => (
                          <p>{e}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </tbody>
          </table>
        </div>
      </Modal>
      {/* description modal */}
      <Modal
        open={descriptionOpen}
        onClose={handleDescriptionclose}
        style={{ overflow: "scroll" }}
      >
        <div
          style={style}
          className="w-[90%] md:w-[80%]  mt-10 mx-auto relative p-5"
        >
          <div className="p-2 bg-[#f8fafc] rounded-lg">
            <div className="text-[24px] font-semibold text-start text-slate-400 ">
              Description
            </div>
          </div>
          <textarea
            name="dexcription"
            id="description"
            rows="10"
            value={descriptionModal}
            readOnly
            className="w-full p-5 focus:outline-none cursor-default resize-none	text-[20px] text-left text-blue"
          ></textarea>
        </div>
      </Modal>
      {/* header images modal */}
      <Modal
        open={headerimagesOpen}
        onClose={handleHeaderclose}
        style={{ overflow: "scroll" }}
      >
        <div
          style={style}
          className="w-[90%] md:w-[80%]  mt-10 mx-auto relative p-5"
        >
          <div className="p-2 bg-[#f8fafc] rounded-lg">
            <div className="text-[20px] font-semibold text-start text-slate-400 ">
              Heading Image
            </div>
          </div>
          <div className="text-black text-[15px] p-5 w-full">
            <div className="h-[500px] w-[500px] mx-auto">
              <img
                src={`${process.env.REACT_APP_UPLOADS}` + headerImagesModal}
                alt=""
                className="m-auto h-full"
              />
            </div>
          </div>
        </div>
      </Modal>
      {/* images modal */}
      <Modal
        open={imagesOpen}
        onClose={handleimagesclose}
        style={{ overflow: "scroll" }}
      >
        <div
          style={style}
          className="w-[90%] md:w-[80%]  mt-10 mx-auto relative p-5"
        >
          <div className="p-2 bg-[#f8fafc] rounded-lg">
            <div className="text-[20px] font-semibold text-start text-slate-400 ">
              Images
            </div>
          </div>
          <div className="text-black text-[15px] p-5">
            <div className="flex flex-wrap w-full justify-center gap-5">
              {imagesModal.map((data, i) => (
                <>
                  <div
                    key={i}
                    className="h-[150px] w-[150px] border-2 border-black"
                  >
                    <img
                      src={`${process.env.REACT_APP_UPLOADS}` + data}
                      alt=""
                      className="m-auto"
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ServiceManagement;
