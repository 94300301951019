import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
// import CompnayDetail from "./CompnayDetail";
import Button from "../../components/helper/Button/Button";
import { useFormik } from "formik";
import api from "../../helper/api";
import { DEVELOPER } from "../../helper/endpoints";
import validationSchema from "./ApplyNowSchema";
import SucessSnackBar from "../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../components/SnackBars/SnackBar";
import Heading from "../helper/Heading";
import CompnayDetail from "../../pages/Career/CompnayDetail";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  location: "",
  SchoolInstitute: "",
  SchoolDegree: "",
  SchoolLocation: "",
  images: {},
  totalExperience: "",
  startmonth: "Jan",
  startyear: "2012",
  endmonth: "Jan",
  endyear: "2012",
  companyName: "",
  companyLocation: "",
  compnayWebsite: "",
  workingAs: "",
  "reson-for-job-change": "",
  "last-date-of-current-job": "",
};

const Form = ({
  model,
  setModel,
  jobtitle,
  sucessSnackBarOpen,
  setSucessSnackBarOpen,
  warningSnackBarOpen,
  setWarningSnackBarOpen,
}) => {
  // const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  // const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [id, setId] = useState();
  const [currCompanyDetail, setCurrCompanyDetail] = useState([]);
  const [submitCount, setSubmitCount] = useState(0);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (value) => {
      try {
        let qualificationArry = [];
        let School = {};
        let HighSchool = {};
        let Course = {};
        let Diploma = {};
        let PostGraduate = {};
        let Graduate = {};
        for (let keys in value) {
          keys.includes("School") &&
            Object.assign(School, { [keys]: value[keys] });
          keys.includes("HighSchool") &&
            Object.assign(HighSchool, { [keys]: value[keys] });
          keys.includes("Course") &&
            Object.assign(Course, { [keys]: value[keys] });
          keys.includes("Diploma") &&
            Object.assign(Diploma, { [keys]: value[keys] });
          keys.includes("PostGraduate") &&
            Object.assign(PostGraduate, { [keys]: value[keys] });
          keys.includes("Graduate") &&
            Object.assign(Graduate, { [keys]: value[keys] });
        }

        qualificationArry = [
          School,
          HighSchool,
          Course,
          Diploma,
          PostGraduate,
          Graduate,
        ];

        let experienceObject = {
          totalExperience: value.totalExperience,
          reason: value["reson-for-job-change"],
          lastDate: value["last-date-of-current-job"],
        };

        let currCompanyDetailArray = [
          {
            name: value.companyName,
            location: value.companyLocation,
            website: value.compnayWebsite,
            workinAs: value.workingAs,
            startmonth: value.startmonth,
            startyear: value.startyear,
            endmonth: value.endmonth,
            endyear: value.endyear,
          },
        ];

        let workingPeriod = `${value.startmonth} ${value.startyear} To ${value.endmonth} ${value.endyear}`;
        let formData = new FormData();
        formData.append("firstName", value.firstName);
        formData.append("lastName", value.lastName);
        formData.append("email", value.email);
        formData.append("phone", value.phone);
        formData.append("location", value.location);
        formData.append("applyAs", jobtitle);
        formData.append("workingPeriod", workingPeriod);
        formData.append("qualification", JSON.stringify(qualificationArry));
        formData.append("experience", JSON.stringify(experienceObject));
        formData.append(
          "currCompanyDetail",
          JSON.stringify(currCompanyDetailArray)
        ); 
        
        for (let i = 0; i < value.images.length; i++) {
          formData.append("images", value.images[i], value.images[i].name);
        }
        api
          .post(`${process.env.REACT_APP_API + DEVELOPER}`, formData)
          .then(
            (res) => setSubmitCount(0),
            setSucessSnackBarOpen(!sucessSnackBarOpen),
            resetForm(0)
          );
      } catch (err) {
        console.log(err);
        setWarningSnackBarOpen(!warningSnackBarOpen);
      }
    },
  });

  useEffect(() => {
    setCurrCompanyDetail({
      companyName: values.companyName,
      companyLocation: values.companyLocation,
      compnayWebsite: values.compnayWebsite,
      workingAs: values.workingAs,
      startmonth: values.startmonth,
      startyear: values.startyear,
      endmonth: values.endmonth,
      endyear: values.endyear,
    });
  }, [
    values.companyName,
    values.companyLocation,
    values.compnayWebsite,
    values.workingAs,
    values.startmonth,
    values.startyear,
    values.endmonth,
    values.endyear,
  ]);

  const [acedemicQualification, setAcedemicQualification] = useState([
    {
      qualification: "School",
      institute: (
        <input
          type="text"
          className="border border-[#34a0ff] text-gray-400 w-full rounded pl-2 outline-none"
          name="SchoolInstitute"
          onChange={handleChange}
          // value={values.SchoolInstitute}
        />
      ),
      degree: (
        <input
          type="text"
          className="border border-[#34a0ff] text-gray-400 w-full rounded pl-2 outline-none"
          name="SchoolDegree"
          onChange={handleChange}
          // value={values.SchoolDegree}
        />
      ),
      location: (
        <input
          type="text"
          className="border border-[#34a0ff] text-gray-400 w-full rounded pl-2 outline-none"
          name="SchoolLocation"
          onChange={handleChange}
          // value={values.SchoolLocation}
        />
      ),
    },
  ]);

  const [compnayDetail, setCompnayDetail] = useState([
    <CompnayDetail
      values={values}
      handleChange={handleChange}
      setId={setId}
      errors={errors}
      submitCount={submitCount}
    />,
  ]);

  const handleQualifiactionAdded = (event) => {
    !acedemicQualification.find(
      (data) => data.qualification === event.target.value
    ) &&
      setAcedemicQualification((prevState) => [
        ...prevState,
        {
          qualification: event.target.value,
          institute: (
            <input
              type="text"
              className="border border-[#34a0ff] text-gray-400 w-full rounded pl-2 outline-none"
              name={`${event.target.value}Institute`}
              onChange={handleChange}
              value={values[`${event.target.value}Institute`]}
            />
          ),
          degree: (
            <input
              type="text"
              className="border border-[#34a0ff] text-gray-400 w-full rounded pl-2 outline-none"
              name={`${event.target.value}Degree`}
              onChange={handleChange}
              value={values[`${event.target.value}Degree`]}
            />
          ),
          location: (
            <input
              type="text"
              className="border border-[#34a0ff] text-gray-400 w-full rounded pl-2 outline-none"
              name={`${event.target.value}Location`}
              onChange={handleChange}
              value={values[`${event.target.value}Location`]}
            />
          ),
        },
      ]);
  };

  return (
    // <Modal
    //   open={model}
    //   onClose={() => setModel(false)}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-descriptionArray"
    //   style={{ overflow: "scroll" }}
    // >

    <div className="w-[90%] md:w-[80%]  mt-10 mx-auto relative">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-between order-1 w-full px-2 py-2 bg-white md:order-none md:py-4 md:px-5 lg:px-8 2xl:px-12 rounded-2xl"
      >
        <Heading
          styles="absolute w-full top-6 esm:top-12 text-center mx-auto"
          image="/assets/images/Hiredeveloper/Details.svg"
          text="Personal details"
        />
        <div className="flex justify-between w-full h-full gap-4 mt-10">
          <div className="space-y-4 w-full mg:w-[20%]">
            <div className="flex flex-col gap-4 md:flex-row">
              <div className=" w-full md:w-[50%] ">
                <input
                  className="w-full lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
                  placeholder="First name"
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName}
                />
                <div className="mt-1">
                  <p className="ml-2 text-xs text-red-500">
                    {submitCount === 1 && errors.firstName}
                  </p>
                </div>
              </div>

              <div className=" w-full  md:w-[50%]">
                <input
                  className="w-full lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
                  placeholder="Last name"
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName}
                />

                <div className="mt-1">
                  <p className="ml-2 text-xs text-red-500">
                    {submitCount === 1 && errors.lastName}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <input
                className="w-full border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
                placeholder="Enter your valid email"
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email}
              />

              <div className="mt-1">
                <p className="ml-2 text-xs text-red-500">
                  {submitCount === 1 && errors.email}
                </p>
              </div>
            </div>

            <div>
              <input
                className="w-full border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
                placeholder="Mobile number"
                type="number"
                name="phone"
                onChange={handleChange}
                value={values.phone}
              />
              <div className="mt-1">
                <p className="ml-2 text-xs text-red-500">
                  {submitCount === 1 && errors.phone}
                </p>
              </div>
            </div>
            <div className="w-full border border-[#34a0ff] text-gray-400 rounded-lg py-2 px-4">
            <label htmlFor="career" className="mr-3 text-gray-400">Choose a career:</label>
            <select name="career" id="career" className="border rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-blue-400">
              <option value="web Developers">Fresher</option>
              <option value="React js">Expreience</option>
              <option value="Full stack">Trainee (Fresher)</option>
            </select>
          </div>
            <div>
              <textarea
                rows={4}
                className="w-full border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
                placeholder="Location"
                type="text"
                name="location"
                onChange={handleChange}
                value={values.location}
              />
              <div className="">
                <p className="ml-2 text-xs text-red-500">
                  {submitCount === 1 && errors.location}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-2">
          <h2 className="text-lg font-extrabold underline text-blue fold:text-xl md:text-2xl lg:text-3xl whitespace-nowrap">
            Academic qualification
          </h2>
          <div className="flex gap-2 mt-4 mb-2 overflow-auto text-xs text-center md:gap-4 text-blue md:text-base">
            <h4 className="flex-1 text-start">Qualification</h4>
            <h4 className="flex-1">Institute</h4>
            <h4 className="flex-1">Degree</h4>
            <h4 className="flex-1">Location</h4>
          </div>

          <div className="space-y-2">
            {acedemicQualification.map((data, index) => {
              return (
                <div key={index} className="flex items-center gap-2 md:gap-4">
                  <span className="flex-1 text-xs text-blue md:text-sm">
                    {data.qualification}
                  </span>
                  <span className="flex-1">{data.institute}</span>
                  <span className="flex-1">{data.degree}</span>
                  <span className="flex-1">{data.location}</span>
                  <div className="mt-1"></div>
                </div>
              );
            })}
          </div>

          <div className="grid grid-cols-4  mt-1">
            <div></div>

            <p className="ml-2 text-xs text-red-500">
              {submitCount === 1 &&
                errors.SchoolInstitute &&
                " Institute is required !"}
            </p>
            <p className="ml-2 text-xs text-red-500">
              {submitCount === 1 &&
                errors.SchoolDegree &&
                " Degree is required !"}
            </p>
            <p className="ml-2 text-xs text-red-500">
              {submitCount === 1 &&
                errors.SchoolLocation &&
                " Location is required !"}
            </p>
          </div>

          <div className="mt-2 text-center">
            <select
              className="text-sm outline-none text-blue"
              onChange={handleQualifiactionAdded}
            >
              <option disabled>Add any other qualification</option>
              <option value="HighSchool">Hight School</option>
              <option value="Course">Course</option>
              <option value="Diploma">Diploma</option>
              <option value="PostGraduate">Post Graduate</option>
              <option value="Graduate">Graduate</option>
            </select>
          </div>
        </div>
        <div className="mt-2">
          <h2 className="text-lg font-extrabold underline text-blue fold:text-xl md:text-2xl lg:text-3xl whitespace-nowrap">
            Work experience
          </h2>

          <div className="flex flex-col w-full gap-4 my-4 md:flex-row">
            <div className=" w-full md:w-[50%] ">
              <input
                className="w-full lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
                placeholder="Total experience"
                type="text"
                name="totalExperience"
                onChange={handleChange}
                value={values.totalExperience}
              />
              <div className="mt-1">
                <p className="ml-2 text-xs text-red-500">
                  {submitCount === 1 && errors.totalExperience}
                </p>
              </div>
            </div>
            <div className=" w-full md:w-[50%] ">
              <input
                className="w-full lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
                placeholder="Reason for a job change"
                type="text"
                name="reson-for-job-change"
                onChange={handleChange}
                value={values["reson-for-job-change"]}
              />
              <div className="mt-1">
                <p className="ml-2 text-xs text-red-500">
                  {submitCount === 1 && errors["reson-for-job-change"]}
                </p>
              </div>
            </div>
          </div>
          <div>
            <input
              className="w-full md:w-[calc(50%-8px)] lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
              placeholder="Last date of current job"
              type="text"
              name="last-date-of-current-job"
              onChange={handleChange}
              value={values["last-date-of-current-job"]}
            />

            <div className="mt-1">
              <p className="ml-2 text-xs text-red-500">
                {submitCount === 1 && errors["last-date-of-current-job"]}
              </p>
            </div>
          </div>
        </div>

        <div className="my-5">
          <h2 className="text-lg font-extrabold underline text-blue fold:text-xl md:text-2xl lg:text-3xl whitespace-nowrap">
            Current company detail
          </h2>
          
          <CompnayDetail
            values={values}
            handleChange={handleChange}
            setId={setId}
            errors={errors}
            submitCount={submitCount}
          />

          <button
            type="button"
            className="my-4 text-blue"
            onClick={() =>
              setCompnayDetail((prevState) => [
                ...prevState,
                <CompnayDetail handleChange={handleChange} setId={setId} />,
              ])
            }
          >
            + Add any other compnay
          </button>
        </div> */}
        <div>
          <p className="text-xs text-gray-600">
            Note: you have to only upload jpg, png, gif, jpeg, doc, docx, pdf
            type of files.
          </p>
          <div className="flex items-center justify-center gap-2 py-2 border-dashed border-blue border-[3px] mt-4 mb-8 px-4">
            <div>
              <input
                id="hire-input"
                type="file"
                className="hidden"
                placeholder="Choose file(s)"
                multiple
                name="images"
                // onChange={(event) =>
                //   setFieldValue("images", event.target.files)
                // }
                onChange={(event) => {
                  // handleFileChange(event);
                  setFieldValue("images", Array.from(event.target.files)); // Set the array of files to Formik
                }}
              />
              <div className="mt-1">
                <p className="ml-2 text-xs text-red-500">
                  {submitCount === 1 &&
                    values.images.length == undefined &&
                    "document is required "}
                  {submitCount === 1 && errors["images"]}
                </p>
              </div>
            </div>

            <label htmlFor="hire-input" className="cursor-pointer min-w-[42px]">
              <img src="/assets/images/about us/file-upload.png" alt="" />
            </label>
            <label
              htmlFor="hire-input"
              className="overflow-hidden text-gray-600 capitalize cursor-pointer whitespace-nowrap"
            >
              upload your documents if you have any
            </label>
          </div>
          <div className="mb-2 space-y-2">
            <div className="flex items-center gap-2">
              <img src="/assets/images/Career/location.png" alt="" />
              <p className="text-sm text-blue">
                301, green plaza, golden chowk, golden city rd, mota varachha,
                surat
              </p>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/images/Career/marker.png" alt="" />
              <p className="text-sm text-blue">
                are you comfortable commuting to this job's location
              </p>
            </div>
          </div>
        </div>
        <Button
          style="mb-4 bg-blue rounded-[8px] w-[200px] mx-auto py-2 text-white mt-5"
          text="Submit"
          type="submit"
          onClick={() => setSubmitCount(1)}
        />
      </form>
    </div>
    // </Modal>
  );
};

export default Form;
