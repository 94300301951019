import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/AdminPanelComponents/Header";
import Sidebar from "../../components/AdminPanelComponents/Slidebar";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Pagination, Modal } from "@mui/material";
import api from "../../helper/api";
import { DEVELOPER } from "../../helper/endpoints";
import ScrollContainer from "react-indiana-drag-scroll";
import { useSelector } from "react-redux";
import { pagesData } from "../../common/Data/PagesData";
import { IoCloseSharp } from "react-icons/io5";
import CustomPagination from "./Pagination/CustomPagination";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: 12,
};

const HireDeveloper = () => {
  const adminDetails = useSelector((state) => state.adminReducer.data);
  const [permission, setPermission] = useState({ actions: {} });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hireData, setHireData] = useState([]);
  // const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [developerData, setDeveloperData] = useState([]);
  const [modeltital, setModeltital] = useState("");
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [selectedDeveloper, setSelectedDeveloper] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const sidebarRef = useRef(null);
  // const sidebarRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null); // For storing the selected file

  const handleFileClick = (fileName) => {
    setSelectedFile(fileName); // Set the selected file
    setRightSidebarOpen(true); // Open the sidebar
  };
  const handleViewDetails = (developer) => {
    setSelectedDeveloper(developer);
    setRightSidebarOpen(true);
  };

  const handleOpen = (data, type) => {
    setOpen(true);
    if (type == "description") {
      // [data]
      let array = [];
      array.push(data);
      setDeveloperData(array);
    } else {
      setDeveloperData(data);
    }
    setModeltital(type);
  };

  const handleClose = () => {
    setOpen(false);
    setDeveloperData("");
  };

  const getDeveloperData = async () => {
    api
      .getWithToken(
        `${
          process.env.REACT_APP_API + DEVELOPER + `?page=${page+1}&limit=${limit}`
        }`
      )
      .then((res) => {
        setTotalPage(res.data.totalPages);
        setHireData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const hendelDelete = (id) => {
    api
      .delete(`${process.env.REACT_APP_API + DEVELOPER + `/${id}`}`)
      .then((res) => {
        if (hireData.length === 1) {
          setPage(page - 1);
        }
        getDeveloperData();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDeveloperData();
  }, [page,totalPage,limit]);

  useEffect(() => {
    if (adminDetails.role_ID.permissions) {
      const currentData = adminDetails.role_ID.permissions.filter(
        (res) => res.module === pagesData.hireManagement
      );
      if (currentData.length !== 0) {
        setPermission(currentData[0]);
      }
    }
  }, [adminDetails.role_ID]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setRightSidebarOpen(false); // Close the sidebar when clicking outside
      }
    };
  
    if (rightSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [rightSidebarOpen]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="container mx-auto bg-white ">
          <div className="w-full p-5">
            <header className="relative py-4 border-b border-slate-100">
              <h2 className="text-sm font-semibold text-slate-800">
                Hire Management
              </h2>
            </header>
            <div className="overflow-x-auto">
              <table className="w-full table-auto ">
                <thead className="font-semibold uppercase text-slate-400 bg-slate-50">
                  <tr>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Name
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Email
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Phone No.
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Location
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Apply As
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Files
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Details
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>

                {hireData?.map((data) => (
                  <tbody key={data._id}>
                    <tr className="border-b-2 hover:bg-slate-50">
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-blue">
                          {data.firstName + " " + data.lastName}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.email}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.phone}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.location}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.applyAs}
                        </div>
                      </td>
                      {/*  */}
                      <td className="p-2 text-center whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          <button
                            onClick={() => handleOpen(data.images, "Files")}
                            className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1"
                            disabled={!permission.actions.view}
                          >
                            Resume
                          </button>
                        </div>
                      </td>
                      <td className="p-2 text-center whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          <button
                            onClick={() => handleViewDetails(data)}
                            className="text-[15px] text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-2 py-1"
                            disabled={!permission.actions.view}
                          >
                            All Details
                          </button>
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[#1e293b]">
                          <IconButton
                            onClick={() => hendelDelete(data._id)}
                            disabled={!permission.actions.delete}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
          {/* <nav className="p-3" aria-label="Page navigation example">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                page={page}
                onChange={(e, value) => setPage(value)}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </nav> */}
          <div className="flex items-center justify-end">
            <CustomPagination
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              totalItems={totalPage || 0} // Pass totalItems instead of count
            />
          </div>
        </div>
      </div>
      <div
        ref={sidebarRef} 
        className={`fixed top-0 right-0 h-full bg-white shadow-2xl transition-transform duration-300 ease-in-out transform ${
          rightSidebarOpen ? "translate-x-0" : "translate-x-full"
        } md:w-[500px] lg:w-[600px] xl:w-[500px]`} // Increased width for larger screens
      >
        <div className="flex flex-col h-full">
          {/* Main content container with two sections */}
          <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
         
            {/* Developer Details Section (Right side) */}
            <div className="w-full md:w-[300px] xl:w-[500px] pt-20 rounded-lg  mx-3">
              <div className="flex items-center justify-between border-b mb-5">
                <h3 className="text-xl font-semibold text-gray-800">
                  Developer Details
                </h3>
                <button
                  className="p-1 rounded-full bg-gray-100 hover:bg-gray-200 transition"
                  onClick={() => setRightSidebarOpen(false)} // Close sidebar when button is clicked
                >
                  <IoCloseSharp className="text-2xl text-gray-600" />
                </button>
              </div>

              {selectedDeveloper && (
                <div className="space-y-4">
                  <div className="text-gray-700">
                    <p className="text-xs text-gray-500">First Name</p>
                    <p className="text-base font-medium">
                      {selectedDeveloper.firstName}
                    </p>
                  </div>
                  <div className="text-gray-700">
                    <p className="text-xs text-gray-500">Last Name</p>
                    <p className="text-base font-medium">
                      {selectedDeveloper.lastName}
                    </p>
                  </div>
                  <div className="text-gray-700">
                    <p className="text-xs text-gray-500">Email</p>
                    <p className="text-base font-medium">
                      {selectedDeveloper.email}
                    </p>
                  </div>
                  <div className="text-gray-700">
                    <p className="text-xs text-gray-500">Phone</p>
                    <p className="text-base font-medium">
                      {selectedDeveloper.phone}
                    </p>
                  </div>
                  <div className="text-gray-700">
                    <p className="text-xs text-gray-500">Location</p>
                    <p className="text-base font-medium">
                      {selectedDeveloper.location}
                    </p>
                  </div>
                  <div className="text-gray-700">
                    <p className="text-xs text-gray-500">Apply As</p>
                    <p className="text-base font-medium">
                      {selectedDeveloper.applyAs}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
>
  <div
    onClick={(e) => e.stopPropagation()} // Prevent closing when clicking  inside 
    className="relative w-[85%] h-[90%] bg-white p-5 rounded-lg shadow-lg"
  >
    <button
      onClick={handleClose}
      className="absolute top-5 right-5 bg-white p-2 rounded-full z-10"
    >
      ✖
    </button>

    {modeltital === "Files" && developerData ? (
      developerData.map((data, index) => (
        <div key={index} className="w-full h-full flex items-center justify-center">
          {typeof data === "string" && data.match(/\.pdf$/) ? (
            <iframe
              src={`${process.env.REACT_APP_UPLOADS}${data}`}
              className="w-full h-full"
            />
          ) : data.match(/\.(doc|docx)$/) ? (
            <div className="text-center">
              <a
                href={`${process.env.REACT_APP_UPLOADS}${data}`}
                download
                className="text-blue-600 underline"
              >
                not upload Download {data}
              </a>
            </div>
          ) : (
            <img
              src={`${process.env.REACT_APP_UPLOADS}${data}`}
              className="max-w-full max-h-full"
              alt={data}
            />
          )}
        </div>
      ))
    ) : (
      <div className="text-center p-5">
        {developerData}
      </div>
    )}
  </div>
</Modal>

    </div>
  );
};

export default HireDeveloper;
//